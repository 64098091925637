












































import ResolveFromCondition from '@/includes/logic/Modules/models/conditions/ResolveFromCondition'
import { InputSetups } from '@/mixins/input-setups'
import SelectOptionsMixin from '@/mixins/SelectOptionsMixin'
import ResolveFromRankCondition
  from '@/includes/logic/Modules/models/conditions/ResolveFromRankCondition/ResolveFromRankCondition'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      ResolveFromRankCondition,
    }
  }
})
export default class ResolveFromConditionView extends Mixins(UseFields, InputSetups, SelectOptionsMixin) {
  @VModel() model!: ResolveFromCondition

  @Prop() prefix!: string
}
